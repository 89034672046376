<template>
  <div class="qingwu">
    <div class="admin_main_block">
      <div class="admin_breadcrumb">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/Admin/index' }"
            >首页</el-breadcrumb-item
          >
          <el-breadcrumb-item>设备管理</el-breadcrumb-item>
          <el-breadcrumb-item>入库列表</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
    </div>
    <div class="admin_main_block admin_m15">
      <div class="header-form">
        <div class="admin_main_block_left">
          <el-form>
            <el-form-item label="批次">
              <el-input
                type="text"
                v-model="where.orderBatch"
                maxlength="100"
                clearable
              ></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" plain icon="el-icon-search" @click="listData()"
                >条件筛选</el-button
              >
            </el-form-item>
          </el-form>
        </div>

        <div class="admin_main_block_right">
          <el-row :gutter="20">
            <el-col :span="12">
              <el-button type="primary" @click="add" icon="el-icon-plus"
                >新增</el-button
              >
            </el-col>
            <el-col :span="12">
              <el-button
                type="primary"
                @click="exportData"
                disabled
                icon="el-icon-tickets"
                >导出数据</el-button
              >
            </el-col>
          </el-row>
        </div>
      </div>
      <div class="admin_table_main">
        <el-table
          :data="list"
          v-loading="loading"
          element-loading-text="拼命加载中"
          element-loading-spinner="el-icon-loading"
          element-loading-background="rgba(0, 0, 0, 0.1)"
          @selection-change="handleSelectionChange"
        >
          <el-table-column prop="orderBatch" label="批次编号"></el-table-column>
          <el-table-column
            prop="deviceManufacturerName"
            label="设备厂商"
          ></el-table-column>
          <el-table-column
            prop="deviceTypeName"
            label="设备类型"
          ></el-table-column>
          <el-table-column prop="typeNo" label="设备型号"></el-table-column>
          <el-table-column prop="price" label="押金(元)"></el-table-column>
          <!--                    <el-table-column prop="shopName" label="S/N前缀"></el-table-column>-->
          <el-table-column prop="merchantName" label="起-止">
            <template slot-scope="scope">
              {{ scope.row.posSnStart }}-{{ scope.row.posSnEnd }}
            </template>
          </el-table-column>
          <el-table-column prop="counts" label="数量(台)"></el-table-column>
          <el-table-column prop="createTime" label="创建时间"></el-table-column>
          <el-table-column label="操作" fixed="right" width="80px">
            <template slot-scope="scope">
              <el-button
                type="primary"
                class="el-icon-tickets"
                @click="
                  $router.push({
                    name: 'devicelist',
                    query: { orderBatch: scope.row.orderBatch },
                  })
                "
                >查看</el-button
              >
            </template>
          </el-table-column>
        </el-table>

        <div class="admin_table_main_pagination">
          <el-pagination
            @current-change="currentChange"
            @size-change="handleSizeChange"
            background
            :total="totalData"
            :page-size="pageSize"
            :current-page="currentPage"
            :page-sizes="[10, 20, 50, 100]"
            layout="sizes, prev, pager, next, total, jumper"
          ></el-pagination>
        </div>
      </div>
      <info :row="row" @dialog="getDialog" v-if="dialogCancelVisible"></info>
    </div>
  </div>
</template>

<script>
import info from "@/components/admin/device/info.vue";
export default {
  components: {
    info,
  },
  props: {
    state: Number,
  },
  data() {
    return {
      list: [],
      date: "",
      row: {},
      loading: false,
      dialogDetailVisible: false,
      dialogCancelVisible: false,
      totalData: 0, // 总条数
      pageSize: 20,
      currentPage: 0,
      orderStatusList: [],
      selectId: "",
      where: {
        page: 0,
        limit: 0,
        orderBatch: "",
      },
    };
  },
  methods: {
    listData() {
      this.loading = true;
      this.where.page = this.currentPage;
      this.where.limit = this.pageSize;
      this.$post(this.$api.deviceBillList, this.where).then((res) => {
        this.totalData = res.data.total;
        this.list = res.data.records;
        this.loading = false;
      });
    },
    handleSelectionChange(e) {
      let ids = [];
      e.forEach((v) => {
        ids.push(v.id);
      });
      this.selectId = ids.join(",");
    },
    currentChange(e) {
      this.currentPage = e;
      this.listData();
    },
    handleSizeChange(e) {
      this.pageSize = e;
      this.listData();
    },
    orderDate(obj) {
      if (obj) {
        this.where.startTime = obj[0];
        this.where.endTime = obj[1];
      }
    },
    exportData() {
      // console.log("exportData");
    },
    add() {
      this.$router.push({ name: "deviceBatchAdd" });
    },
    sendDialog(row) {
      this.row = row;
      this.dialogDetailVisible = true;
    },
    getDialog(val) {
      this.dialogDetailVisible = val;
    },
  },
  created() {
    this.listData();
  },
};
</script>
<style lang="scss" scoped>
.endDate {
  color: #c0c4cc;
}
.el-image {
  height: 100px;
  width: 100px;
}
.el-select {
  width: 150px;
}
</style>
